<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators'

const TRANSLATION = {
  feedback: {
    update: {
      success: app.$t('notifications:filters.feedback.updated.success'),
      canNotEditMediationPlanAssociated: app.$t('notifications:filters.feedback.updated.error.cant_edit_mediation_plan_associated'),
      canNotActivateFilterExecutionError: app.$t('notifications.filters:feedback.toggle.execution.error'),
      error: app.$t('notifications:filters.feedback.updated.error')
    }
  }
}

const UPDATE_ERRORS_TYPE = {
  cant_edit_mediation_plan_associated: TRANSLATION.feedback.update.canNotEditMediationPlanAssociated,
  cant_activate_filter_execution_error: TRANSLATION.feedback.update.canNotActivateFilterExecutionError,
  default: TRANSLATION.feedback.error
}

export default {
  name: 'ManagePage',

  components: {
    Action: () => import('@/components/general/Action'),
    TextareaField: () => import('@/components/general/TextareaField'),
    ActionBar: () => import('@/components/general/ActionBar'),
    SelectField: () => import('@/components/general/SelectField'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    ContentHeader: () => import('@/components/general/ContentHeader')
  },

  data () {
    return {
      applicabilityOptions: [
        { text: this.$t('notifications.filters.manage:field.option.solution'), value: 'solution' },
        { text: this.$t('notifications.filters.manage:field.option.track'), value: 'learning_path' },
        { text: this.$t('notifications.filters.manage:field.option.program'), value: 'program', visible: false }
      ],

      filterTypeOptions: [
        { text: this.$t('notifications.filters.manage:field.option.status'), value: 'by_status', underDescription: this.$t('notifications.filters.manage:field.option.status.info') },
        { text: this.$t('notifications.filters.manage:field.option.status_days'), value: 'by_status_days', underDescription: this.$t('notifications.filters.manage:field.option.status_days.info') },
        { text: this.$t('notifications.filters.manage:field.option.date'), value: 'by_enrollment_date', underDescription: this.$t('notifications.filters.manage:field.option.date.info') },
        { text: this.$t('notifications.filters.manage:field.option.progress'), value: 'by_progress', underDescription: this.$t('notifications.filters.manage:field.option.progress.info') },
        { text: this.$t('notifications.filters.manage:field.option.sql'), value: 'full_sql', underDescription: this.$t('notifications.filters.manage:field.option.sql.info') }
      ],

      statusOptions: [
        { text: this.$t('notifications.filters.manage:field.option.initial'), value: 'initial' },
        { text: this.$t('notifications.filters.manage:field.option.in_progress'), value: 'in_progress' },
        { text: this.$t('notifications.filters.manage:field.option.finished'), value: 'finished' },
        { text: this.$t('notifications.filters.manage:field.option.dropout'), value: 'dropout' },
        { text: this.$t('notifications.filters.manage:field.option.expired'), value: 'expired' },
        { text: this.$t('notifications.filters.manage:field.option.canceled'), value: 'canceled' }
      ],

      dateOptions: [
        { text: this.$t('notifications.filters.manage:field.option.enrollment_date'), value: 'enrollment_init_date' },
        { text: this.$t('notifications.filters.manage:field.option.session_date'), value: 'session_init_date' },
        { text: this.$t('notifications.filters.manage:field.option.finish_date'), value: 'finish_date' }
      ],

      operatorOptions: [
        { text: this.$t('global.operator.greater_equal'), value: '>=' },
        { text: this.$t('global.operator.equal'), value: '=' },
        { text: this.$t('global.operator.lower_equal'), value: '<=' }
      ],

      formData: {
        name: null,
        description: null,
        applicability: null,
        filterType: null,
        status: null,
        daysOnStatus: 1,
        referenceDate: null,
        daysReferenceDate: 0,
        progressOperator: null,
        progress: 1,
        sql: null
      },
      backUrl: { name: 'notifications.filters' }
    }
  },

  watch: {
    'formData.applicability' (value) {
      if (!this.isEditing) {
        this.formData.filterType = null
        this.formData.status = null
      }

      this.statusOptions.forEach(element => {
        element.visible = true
      })
      this.filterTypeOptions.forEach(element => {
        element.visible = true
      })

      if (value === 'solution') {
        this.filterTypeOptions[3].visible = false
      } else if (value === 'learning_path') {
        this.filterTypeOptions[2].visible = false

        this.statusOptions[3].visible = false
        this.statusOptions[4].visible = false
        this.statusOptions[5].visible = false
      } else if (value === 'program') {
        this.filterTypeOptions[2].visible = false

        this.statusOptions[5].visible = false
      }
    }
  },

  validations: {
    formData: {
      name: { required },
      description: { required },
      applicability: { required },
      filterType: { required },

      status: {
        required: requiredIf(function () {
          return this.showStatusFields
        })
      },

      daysOnStatus: {
        required: requiredIf(function () {
          return this.showStatusDaysField
        }),
        minValue: minValue(1),
        maxValue: maxValue(30)
      },

      referenceDate: {
        required: requiredIf(function () {
          return this.showReferenceDateFields
        })
      },

      daysReferenceDate: {
        required: requiredIf(function () {
          return this.showReferenceDateFields
        }),
        minValue: minValue(-30),
        maxValue: maxValue(30)
      },

      progressOperator: {
        required: requiredIf(function () {
          return this.showProgressFields
        })
      },

      progress: {
        required: requiredIf(function () {
          return this.showProgressFields
        }),
        minValue: minValue(1),
        maxValue: maxValue(100)
      },

      sql: {
        required: requiredIf(function () {
          return this.showSQLFields
        })
      }
    }
  },

  computed: {
    isEditing () {
      return !!(this.$route.params.id || this.formData.id)
    },

    showStatusFields () {
      return ['by_status', 'by_status_days'].includes(this.formData.filterType)
    },

    showStatusDaysField () {
      return this.formData.filterType === 'by_status_days'
    },

    showReferenceDateFields () {
      return this.formData.filterType === 'by_enrollment_date'
    },

    showProgressFields () {
      return this.formData.filterType === 'by_progress'
    },

    showSQLFields () {
      return this.formData.filterType === 'full_sql'
    },

    getFilterTypeUnderDescription () {
      if (!this.formData.filterType) return null
      return this.filterTypeOptions.filter(item => {
        return item.value === this.formData.filterType ? item : null
      })[0].underDescription
    },

    getSolutionStatusWarningUnderDescription () {
      if (this.formData.applicability === 'solution' && ['dropout', 'expired'].includes(this.formData.status)) {
        return this.$t('notifications.filters.manage:field.option.status.warning')
      }
      return this.$t('notifications.filters.manage:field.option.status.description')
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveFilter',
      'attemptGetFilter',
      'attemptUpdateFilter'
    ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.UPDATE_ERRORS_TYPE = UPDATE_ERRORS_TYPE
    },

    loadFormData (data) {
      this.formData.name = data.name
      this.formData.description = data.description
      this.formData.applicability = data.type

      if (data.whereParts.length > 0) {
        const wherePart = data.whereParts[0]
        this.formData.filterType = wherePart.type

        switch (this.formData.filterType) {
          case 'by_status':
            this.formData.status = wherePart.status
            break
          case 'by_status_days':
            this.formData.status = wherePart.status
            this.formData.daysOnStatus = wherePart.days
            break
          case 'by_enrollment_date':
            this.formData.referenceDate = wherePart.reference
            this.formData.daysReferenceDate = wherePart.days
            break
          case 'by_progress':
            this.formData.progressOperator = wherePart.operator
            this.formData.progress = wherePart.value
            break
        }
      } else {
        this.formData.filterType = 'full_sql'
        this.formData.sql = data.fullSql
      }
    },

    formatFormData () {
      const data = {}
      data.name = this.formData.name
      data.description = this.formData.description
      data.type = this.formData.applicability

      const wherePart = {}
      wherePart.type = this.formData.filterType
      if (this.showStatusFields) {
        wherePart.status = this.formData.status

        if (this.showStatusDaysField) {
          wherePart.days = this.formData.daysOnStatus
        }
      }

      if (this.showReferenceDateFields) {
        wherePart.reference = this.formData.referenceDate
        wherePart.days = this.formData.daysReferenceDate
      }

      if (this.showProgressFields) {
        wherePart.operator = this.formData.progressOperator
        wherePart.value = this.formData.progress
      }

      if (this.showSQLFields) {
        data.where_parts = null
        data.full_sql = this.formData.sql
      } else {
        data.full_sql = null
        data.where_parts = [wherePart]
      }

      return data
    },

    submitCreation () {
      this.setFetching(true)
      this.attemptSaveFilter(this.formatFormData()).then((response) => {
        this.setFeedback({ message: this.$t('notifications:filters.feedback.created.success') })
        this.$router.push(this.backUrl)
      }).catch((response) => {
        this.setFeedback({ message: this.$t('notifications:filters.feedback.created.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate () {
      this.setFetching(true)

      const params = {
        id: this.$route.params.id,
        data: this.formatFormData()
      }

      this.attemptUpdateFilter(params)
        .then(() => {
          this.setFeedback({ message: TRANSLATION.feedback.update.success })
        })
        .catch((response) => {
          const error = response.data.error
          console.log(error)

          if (UPDATE_ERRORS_TYPE[error.message]) {
            this.setFeedback({ message: UPDATE_ERRORS_TYPE[error.message] })
            return
          }

          this.setFeedback({ message: UPDATE_ERRORS_TYPE.default })
        })
        .finally(() => {
          this.setFetching(false)
          this.leave()
        })
    },

    submit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isEditing ? this.submitUpdate() : this.submitCreation()
      } else {
        this.setFeedback({ message: this.$t('global:feedback.validation.error') })
      }
    },

    leave () {
      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    }
  },

  created () {
    this.setup()

    if (this.isEditing) {
      this.setFetching(true)

      const filterId = this.$route.params.id || this.formData.id

      this.attemptGetFilter(filterId)
        .then((response) => {
          this.loadFormData(response)
        })
        .catch((response) => {
          this.setFeedback({ message: this.$t('notifications.filters:feedback.access.error') })
          this.$router.push(this.backUrl)
        })
        .finally(() => {
          this.setFetching(false)
        })
    }
  }
}
</script>

<template>
  <div class="main-content filters-create">
    <content-header
      :title="isEditing ? formData.name : $t('filters.create:header.title')"
      dark-theme
    >
      <action
        slot="back"
        type="button"
        :text="$t('global:back.filters')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <action-bar slot="actionbar" />
      <template slot="buttons">
        <action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit()"
        />
      </template>
    </content-header>
    <div class="main-content-inner">
      <div class="center">
        <div class="filters-create-header">
          <p
            class="filters-create-description"
            v-html="isEditing ? $t('filters.create:header.description.edit') : $t('filters.create:header.description.create')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <form-section>
            <input-field
              v-model="formData.name"
              :under-description="$t('notifications.filters.manage:field.name')"
              :label="$t('global:form.filters.name')"
              :validation="$v.formData.name"
              :counter="50"
            />
          </form-section>
          <form-section>
            <input-field
              v-model="formData.description"
              :under-description="$t('notifications.filters.manage:field.description')"
              :label="$t('global:form.filters.description')"
              :validation="$v.formData.description"
              :counter="200"
            />
          </form-section>
          <form-section>
            <select-field
              v-model="formData.applicability"
              :label="$t('notifications.filters.manage:field.applicability')"
              :validation="$v.formData.applicability"
              :items="applicabilityOptions"
            />
          </form-section>
          <form-section>
            <select-field
              v-model="formData.filterType"
              :label="$t('notifications.filters.manage:field.filterType')"
              :under-description="getFilterTypeUnderDescription"
              :validation="$v.formData.filterType"
              :items="filterTypeOptions"
            />
          </form-section>
          <form-section>
            <select-field
              v-if="showStatusFields"
              v-model="formData.status"
              :under-description="getSolutionStatusWarningUnderDescription"
              :label="$t('notifications.filters.manage:field.status')"
              :validation="$v.formData.status"
              :items="statusOptions"
            />
          </form-section>
          <form-section>
            <input-field
              v-if="showStatusDaysField"
              v-model="formData.daysOnStatus"
              :under-description="$t('notifications.filters.manage:field.options.status_days.description')"
              :min="1"
              :max="30"
              :label="$t('notifications.filters.manage:field.daysOnStatus')"
              :validation="$v.formData.daysOnStatus"
              type="number"
            />
          </form-section>
          <form-section v-if="showReferenceDateFields">
            <div class="inline">
              <select-field
                v-model="formData.referenceDate"
                :label="$t('notifications.filters.manage:field.referenceDate')"
                :validation="$v.formData.referenceDate"
                :items="dateOptions"
              />

              <input-field
                v-model="formData.daysReferenceDate"
                :min="-30"
                :max="30"
                :label="$t('notifications.filters.manage:field.daysReferenceDate')"
                :validation="$v.formData.daysReferenceDate"
                type="number"
              />
            </div>

            <div>
              <span
                class="under-description"
                v-html="$t('notifications.filters.manage:field.option.enrollment_date.description.1')"
              />
              <span
                class="under-description"
                v-html="$t('notifications.filters.manage:field.option.enrollment_date.description.2')"
              />
              <span
                class="under-description mt-8"
                v-html="$t('notifications.filters.manage:field.option.enrollment_date.days.description')"
              />
            </div>
          </form-section>
          <form-section>
            <select-field
              v-if="showProgressFields"
              v-model="formData.progressOperator"
              :label="$t('notifications.filters.manage:field.progress_operator')"
              :validation="$v.formData.progressOperator"
              :items="operatorOptions"
            />
            <input-field
              v-if="showProgressFields"
              v-model="formData.progress"
              :under-description="$t('notifications.filters.manage:field.progress.description')"
              :min="1"
              :max="100"
              :label="$t('notifications.filters.manage:field.progress')"
              has-percent
              :validation="$v.formData.progress"
              type="number"
            />
          </form-section>
          <form-section>
            <textarea-field
              v-if="showSQLFields"
              v-model="formData.sql"
              :under-description="$t('notifications.filters.manage:field.sql.description')"
              :label="$t('notifications.filters.manage:field.sql.instruction')"
              :validation="$v.formData.sql"
              auto-grow
            />
          </form-section>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss"></style>
<style lang="scss" src="../assets/scss/Manage.scss"></style>
